(function( $ ){

  $.fn.filemanager = function(type, options) {
    type = type || 'file';
    console.log("filemanager init", options);
    this.on('click', function(e) {
      var route_prefix = (options && options.prefix) ? options.prefix : '/filemanager';
      var preview_identifier = $(this).data('preview');
      var target_input = $('#' + $(this).data('input'));
      var target_preview = $('#' + preview_identifier);
      window.open(route_prefix + '?type=' + type + '&defaultPath=/' + (tenant_id ?? 'shares'), 'FileManager', 'width=900,height=600');
      window.SetUrl = function (items) {
        console.log({target_input, target_preview, items})
        var file_path = items.map(function (item) {
          return item.url;
        }).join(',');

        // set the value of the desired input to image url
        target_input.val('').val(file_path).trigger('change');

        // clear previous preview
        target_preview.html('');

        // set or change the preview image src
        items.forEach(function (item) {
          let el = $('<img>').attr('src', item.url).attr('id', preview_identifier + '-preview').addClass('img-fluid view-image');
          target_preview.append(el);
        });

        // trigger change event
        target_preview.trigger('change');
      };
      return false;
    });
  }

})(jQuery);
